import React from 'react';

function Experience() {
  const experiences = [
    {
      title: "Full Stack Drupal Developer",
      company: "Srijan Technologies Pvt. ltd.",
      period: "September 2022 – Present",
      responsibilities: [
        "Skilled in Drupal 9 and 10 development",
        "Specializing in custom module creation, theme customization, and site optimization",
        "Experienced in building scalable, high-performance websites",
        "Integrating third-party services and resolving complex technical issues",
        "Proficient in tools like DDEV and S3FS for streamlined development and cloud storage integration"
      ]
    },
    {
      title: "Web Development Intern",
      company: "Srijan Technologies Pvt. ltd.",
      period: "January 2022 - September 2022",
      responsibilities: []
    }
  ];

  return (
    <section id="experience" className="App-section">
      <h2 className="section-title">Work Experience</h2>
      <div className="experience-timeline">
        {experiences.map((exp, index) => (
          <div key={index} className="experience-card">
            <div className="experience-header">
              <h3>{exp.title}</h3>
              <p className="company">{exp.company}</p>
              <p className="period">{exp.period}</p>
            </div>
            {exp.responsibilities.length > 0 && (
              <ul className="responsibilities">
                {exp.responsibilities.map((resp, i) => (
                  <li key={i}>{resp}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Experience;

